import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'
import Icon from '../Icon'

const DonorAvatar = ({
  alt,
  classNames,
  image,
  styles
}) => (
  <div className={classNames.root}>
    {image
      ? (
        <img
          alt={alt}
          src={image}
        />
        )
      : (
        <Icon
          color='shade'
          name='userCircle'
        />
        )}
  </div>
)

export default withStyles(styles)(DonorAvatar)
