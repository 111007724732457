import numbro from 'numbro'

const showNumber = (format) => (value) => {
  switch (format) {
    case 'currency':
      return numbro(value).formatCurrency({ thousandSeparated: true, mantissa: 0 })
    case 'currency_cents':
      return numbro(value).formatCurrency({ thousandSeparated: true, mantissa: 2 })
    case 'percent':
      return numbro(value).format({ output: 'percent', mantissa: 0 })
    case 'number':
      return numbro(value).format({ thousandSeparated: true, mantissa: 0 })
    default:
      return numbro(value).format(format)
  }
}

export const showNumberInPercentage = showNumber('percent')
export const showNumberInCurrency = showNumber('currency')
export const showNumberInCurrencyCents = showNumber('currency_cents')
export const showNumberInThousands = showNumber('number')
export default showNumber
