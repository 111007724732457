import React from 'react'
import { Link } from 'react-router-dom'
import InlineButton from '../InlineButton'
import Heading from 'constructicon/heading'
import Section from 'constructicon/section'
import RichText from 'constructicon/rich-text'

const ChildIntro = ({
  edit,
  name,
  story
}) => (
  <Section
    background='light'
    tag='div'
  >
    <Heading
      size={1.5}
      spacing={{ b: 1 }}
    >
      {`About ${name}`}
      {edit && (
        <InlineButton
          icon='edit'
          tag={Link}
          to={edit}
        >
          Edit
        </InlineButton>
      )}
    </Heading>
    <RichText size={-0.5}>
      <p>{story}</p>
    </RichText>
  </Section>
)

export default ChildIntro
