import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Heading from 'constructicon/heading'
import Modal from 'constructicon/modal'
import Section from 'constructicon/section'

const EditModal = ({
  children,
  classNames,
  onClose,
  open,
  styles,
  title,
  ...props
}) => (
  <Modal
    styles={styles.root}
    contentLabel={title}
    onRequestClose={onClose}
    isOpen={open}
    spacing={0}
    closeIcon={false}
    shouldCloseOnOverlayClick={false}
    {...props}
  >

    <Section tag='header' background='primary' foreground='light' spacing={{ x: 1, y: 0.5 }}>
      <Heading size={2} styles={styles.title}>{title}</Heading>
    </Section>
    <Section>
      {children}
    </Section>
  </Modal>
)

export default withStyles(styles)(EditModal)
