import 'minimal.css'
import '../common/styles'

if (typeof Promise === 'undefined') {
  require('es6-promise').polyfill()
}

if (typeof Object.assign !== 'function') {
  require('es6-object-assign').polyfill()
}

const componentRequireContext = require.context('public/components', true)
const ReactRailsUJS = require('react_ujs')
ReactRailsUJS.useContext(componentRequireContext)
