import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'
import logo from './logo.svg'

import Tooltip from '../../../../../common/components/Tooltip'
import Icon from '../../../../../common/components/Icon'

const PoweredBy = ({ classNames, styles }) => (
  <div className={classNames.root}>
    <span className={classNames.text}>Fundraising powered by</span>
    <a href='https://www.justgiving.com/' target='_blank' rel='noopener noreferrer'>
      <img src={logo} className={classNames.logo} />
    </a>
    <div className={classNames.help}>
      <Tooltip info='JustGiving provides secure payment processing for online donations.'>
        <Icon name='info' styles={styles.icon} />
      </Tooltip>
    </div>
  </div>
)

export default withStyles(styles)(PoweredBy)
