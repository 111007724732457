import React, { Component } from 'react'
import get from 'lodash/get'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { loginUser } from '../../store/session'
import withForm from 'constructicon/with-form'
import form from './form'

import Form from '../../../../../common/components/Form'
import InputField from '../../../../../common/components/InputField'
import LoadingMessage from '../../../../../common/components/LoadingMessage'
import Message from '../Message'

class LoginForm extends Component {
  constructor () {
    super()
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state = { errors: [] }
  }

  handleSubmit (e) {
    e.preventDefault()

    const { form, loginUser } = this.props

    return form
      .submit()
      .then(data => loginUser(data))
      .catch(error => {
        const errors = get(error, 'response.data.Errors', []).map(err => err.ErrorMessage)

        if (errors.length) {
          return this.setState({ errors })
        }
      })
  }

  render () {
    const { form, status, formComponent, location } = this.props

    form.fields.password = form.fields.pField
    const query = new URLSearchParams(location.search)

    return (
      <LoadingMessage
        message='Logging into your account, this may take a few seconds'
        status={status}
      >
        <Form
          isLoading={status === 'fetching'}
          onSubmit={this.handleSubmit}
          errors={this.state.errors}
          noValidate
          {...formComponent}
        >
          <Message show={query.get('message')}>
            We have determined you have an existing JustGiving account, please login to your account below.
          </Message>
          <InputField {...form.fields.email} />
          <InputField {...form.fields.password} />
        </Form>
      </LoadingMessage>
    )
  }
}

const mapState = ({ config, session }) => ({
  ...config,
  email: session.email,
  status: session.status
})

const mapActions = { loginUser }

export default compose(
  withRouter,
  connect(mapState, mapActions),
  withForm(form)
)(LoginForm)
