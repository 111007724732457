import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'
import Icon from '../Icon'

const InlineButton = ({
  children,
  classNames,
  icon,
  margin,
  onClick,
  styles,
  tag: Tag = 'button',
  ...props
}) => (
  <Tag
    className={classNames.root}
    onClick={onClick}
    {...props}
  >
    {icon && <Icon name={icon} />}
    {children}
  </Tag>
)

export default withStyles(styles)(InlineButton)
