export default (props, traits) => {
  const { rhythm } = traits

  return {
    heading: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',

      img: {
        float: 'left',
        marginRight: rhythm(1),
        maxWidth: rhythm(3),
        maxHeight: rhythm(3)
      }
    }
  }
}
