import coins from './coins.png'
import notes from './notes.png'
import stars from './stars.png'

export default (props, traits) => {
  const {
    colors,
    mediaQuery,
    rhythm,
    scale,
    transitions,
    treatments
  } = traits

  return {
    handles: {
      '> :nth-last-child(n+2)': {
        backgroundSize: 'cover',
        overflow: 'hidden',
        borderBottom: `1px solid ${colors.shade}`
      },
      '> :nth-child(1)': { backgroundImage: `url(${stars})` },
      '> :nth-child(2)': { backgroundImage: `url(${coins})` },
      '> :nth-child(3)': { backgroundImage: `url(${notes})` },
      [mediaQuery('sm')]: {
        '> :nth-last-child(n+2)': {
          borderBottomWidth: 0,
          borderRight: `1px solid ${colors.shade}`
        }
      }
    },

    handle: {
      height: '100%',
      minHeight: rhythm(7),
      padding: rhythm(1),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      color: colors.light,
      transition: transitions.easeOut,
      transform: 'scale(1)',

      ':hover': {
        transform: 'scale(1.125)'
      }
    },

    label: {
      marginBottom: rhythm(0.5),
      ...treatments.head
    },

    amount: {
      fontSize: scale(5),
      lineHeight: 1,
      ...treatments.thick
    },

    form: {
      height: '100%',
      padding: rhythm(1),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    },

    field: {
      position: 'relative',
      width: '100%',

      svg: {
        position: 'absolute',
        zIndex: 2,
        top: rhythm(0.5),
        left: rhythm(0.5)
      }
    },

    input: {
      root: {
        marginBottom: rhythm(0.5)
      },
      field: {
        borderWidth: 0,
        paddingLeft: rhythm(1.5)
      }
    }
  }
}
