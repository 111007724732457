import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Introduction from './Introduction'
import Signup from './Signup'
import Login from './Login'
import ResetPassword from './ResetPassword'
import PageNotFound from '../../../../common/components/PageNotFound'

export default (
  <Switch>
    <Route exact path='/' component={Introduction} />
    <Route exact path='/sign_up' component={Signup} />
    <Route exact path='/sign_in' component={Login} />
    <Route exact path='/sign_in/reset_password' component={ResetPassword} />
    <Route path='*' component={PageNotFound} />
  </Switch>
)
