import React from 'react'
import { compose } from 'redux'
import withTraits from '../../../common/lib/withTraits'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Avatar from '../../../common/components/Avatar'
import Button from '../../../common/components/Button'
import Container from 'constructicon/container'
import { showNumberInCurrencyCents } from '../../../common/lib/showNumber'

const ThankYouPage = ({
  amount,
  application,
  child = {},
  classNames,
  donor_name: donorName,
  page_url: pageUrl,
  status,
  styles,
  token
}) => (
  <Container width={24} spacing={1} styles={styles.container}>
    <a className={classNames.logo} href='/'>
      <img alt={application.application_title} src={application.logo_url} />
    </a>
    {status === 'Pending' && (
      <div className={classNames.processing}>
        <strong>Thanks! Your donation is processing</strong>
        <br />There’s nothing else you need to do.
        You will receive an email from JustGiving when it is complete.
      </div>
    )}
    <div className={classNames.card}>
      <h1 className={classNames.title}>
        {`Thank you ${donorName ? donorName.split(' ')[0] : ''} for your donation${
          amount ? ` of ${showNumberInCurrencyCents(amount)}` : ''
        }.`}
      </h1>
      {pageUrl && (
        <div>
          <a className={classNames.avatar} href={pageUrl}>
            <Avatar child={child} />
          </a>
          <Button theme='primary' tag='a' href={pageUrl}>
            Back to {child.firstname}&apos;s page
          </Button>

          <small className={classNames.info}>
            Please note, your donation may take up to 2 minutes to appear in the
            activity feed on {child.firstname ? `${child.firstname}'s` : 'the'}{' '}
            fundraising page.
          </small>
        </div>
      )}
    </div>

    {application.thank_you_banner_url
      ? (
        <a href={application.thank_you_banner_link_url}>
          <img src={application.thank_you_banner_url} />
        </a>
        )
      : ''}
  </Container>
)

export default compose(
  withTraits,
  withStyles(styles)
)(ThankYouPage)
