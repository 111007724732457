import React from 'react'
import * as validators from 'constructicon/lib/validators'

export const passwordComplexity = (msg = 'Password must include at least one number, letter and a special character') => {
  return val => !!val && !/^(?:(?=.*\W)(?=.*[a-zA-Z])(?=.*\d))/.test(val) && msg
}

export default ({ onToggle, classNames, email, application = {} }) => ({
  fields: {
    firstName: {
      label: 'Parent First name',
      type: 'text',
      placeholder: 'FirstName',
      required: true,
      maxLength: 30,
      validators: [
        validators.required('Please enter a first name'),
        validators.alphaNumericSpecial('Please enter a valid first name')
      ]
    },
    lastName: {
      label: 'Parent Last name',
      type: 'text',
      placeholder: 'LastName',
      required: true,
      maxLength: 50,
      validators: [
        validators.required('Please enter a last name'),
        validators.alphaNumericSpecial('Please enter a valid last name')
      ]
    },
    email: {
      label: 'Parent Email Address',
      type: 'email',
      placeholder: 'email@example.com',
      autoComplete: 'email',
      initial: email,
      required: true,
      validators: [
        validators.email('Please enter a valid email address'),
        validators.required('Please enter your email address')
      ]
    },
    emailConfirmation: {
      label: 'Confirm Parent Email Address',
      type: 'email',
      placeholder: 'Re-type your email',
      autoComplete: 'do-not-fill-email',
      onPaste: (e) => e.preventDefault(),
      required: true,
      validators: [
        validators.email('Please enter a valid email address'),
        validators.equalsField('email'),
        validators.required('Please confirm your email address')
      ]
    },
    pField: {
      label: 'Password',
      type: 'password',
      placeholder: '••••••••••',
      autoComplete: 'do-not-fill-password',
      required: true,
      validators: [
        validators.greaterThan(11, 'Password must be at least 12 characters'),
        passwordComplexity('Password must include at least one number, a lower or upper case letter and a special character (#,$,%,&,@ etc.)'),
        validators.required('Please enter your password')
      ]
    },
    passwordConfirmation: {
      label: 'Confirm Password',
      type: 'password',
      placeholder: '••••••••••',
      onPaste: (e) => e.preventDefault(),
      required: true,
      validators: [
        validators.required('Please confirm your password'),
        validators.equalsField('pField', 'Passwords do not match')
      ]
    },
    sfrTerms: {
      label: (
        <small className={classNames.legal}>
          I agree to the {application.application_title}{' '}
          <a
            onClick={e => {
              e.preventDefault()
              onToggle()
            }}
          >
            Privacy Policy
          </a>
        </small>
      ),
      type: 'checkbox',
      required: true,
      validators: [
        validators.required(
          `You must agree to the ${application.application_title} terms and conditions`
        )
      ]
    },
    jgTerms: {
      label: (
        <small className={classNames.legal}>
          I agree to JustGiving’s{' '}
          <a href='https://www.justgiving.com/info/privacy' target='_blank' rel='noopener noreferrer'>
            Privacy Policy
          </a>{' '}
          and{' '}
          <a href='https://www.justgiving.com/info/terms-of-service' target='_blank' rel='noopener noreferrer'>
            Terms of Service
          </a>
        </small>
      ),
      type: 'checkbox',
      required: true,
      validators: [
        validators.required(
          "You must agree to JustGiving's terms and conditions"
        )
      ]
    },
    newsletter: {
      label: (
        <small className={classNames.legal}>
          I would like to receive a monthly newsletter
        </small>
      ),
      initial: true,
      type: 'checkbox'
    }
  }
})
