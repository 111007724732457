export default (props, traits) => {
  const { colors, rhythm } = traits
  const { closeColor, size = 'medium', hideOverlay } = props

  const sizes = {
    xsmall: '24rem',
    small: '36rem',
    medium: '48rem',
    large: '56rem'
  }

  return {
    root: {
      overlay: {
        background: hideOverlay ? 'transparent' : 'rgba(0, 0, 0, 0.5)',
        zIndex: 999
      },
      content: {
        borderRadius: 0,
        borderWidth: 0,
        padding: 0,
        maxWidth: '90vw',
        width: sizes[size]
      },
      container: {
        padding: 0
      },
      close: {
        top: rhythm(1.125),
        color: colors[closeColor],
        svg: {
          display: 'block'
        }
      }
    }
  }
}
