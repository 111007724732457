import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Button from '../Button'
import Icon from '../Icon'
import { Link } from 'react-router-dom'

const Avatar = ({
  child,
  edit,
  classNames,
  styles
}) => (
  <div className={classNames.root}>
    <img src={child.image_url} alt={child.firstname} />
    {edit && (
      <Button
        size={-0.5}
        spacing={{ y: 0.25, x: 0.75 }}
        styles={styles.edit}
        tag={Link}
        to={edit}
        theme='lightPrimary'
      >
        <Icon name='edit' />
        <span>Edit</span>
      </Button>
    )}
  </div>
)

export default withStyles(styles)(Avatar)
