import merge from 'lodash/merge'

export default (props, traits) => {
  const {
    colors,
    radiuses,
    rhythm,
    scale,
    transitions
  } = traits

  const {
    fontScale = -1,
    horizontalOffset = 50,
    noBackground,
    styles,
    textAlign = 'center',
    verticalOffset = -1
  } = props

  const baseStyles = {
    root: {
      position: 'relative',
      display: 'inline-block',
      minWidth: 0,
      ':hover': {
        background: !noBackground && 'rgba(255,255,255,0.25)',
        cursor: 'help'
      },
      ':hover > div:first-child': {
        transform: `translateX(-${horizontalOffset}%) translateY(${rhythm(
          verticalOffset / 2
        )})`,
        opacity: 1,
        visiblity: 'visible'
      }
    },

    tooltip: {
      visiblity: 'hidden',
      opacity: 0,
      pointerEvents: 'none',
      transform: `translateX(-${horizontalOffset}%) translateY(${rhythm(
        verticalOffset
      )})`,
      transition: transitions.easeOut,
      position: 'absolute',
      zIndex: 12,
      bottom: '100%',
      left: '50%',
      lineHeight: 1.5,
      background: colors.light,
      color: colors.dark,
      padding: rhythm(0.5),
      minWidth: rhythm(8),
      fontSize: scale(fontScale),
      textAlign,
      borderRadius: radiuses.medium,
      boxShadow: '0 1px 5px rgba(0,0,0,0.25)',
      textDecoration: 'none',
      ':before': {
        content: '""',
        position: 'absolute',
        bottom: rhythm(-0.333),
        left: `${horizontalOffset}%`,
        background: colors.light,
        width: rhythm(0.666),
        height: rhythm(0.666),
        marginLeft: rhythm(-0.333),
        transform: 'rotate(45deg)',
        boxShadow: '2px 2px 2px rgba(0,0,0,0.125)'
      }
    }
  }

  return merge(baseStyles, styles)
}
