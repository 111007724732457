import React from 'react'

import AuthForm from '../../components/AuthForm'
import { Link } from 'react-router-dom'
import SignupForm from '../../components/SignupForm'
import AppLayout from '../../components/AppLayout'

const Signup = () => (
  <AppLayout>
    <AuthForm
      heading='Parent/Guardian Registration'
      formComponent={SignupForm}
      footer={<Link to='/sign_in'>Already have an account?</Link>}
    />
  </AppLayout>
)

export default Signup
