import React from 'react'
import { compose } from 'redux'
import withToggle from 'constructicon/with-toggle'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import EditCashModal from '../EditCashModal'
import InlineButton from '../InlineButton'
import Metric from '../Metric'
import Progress from '../Progress'
import Section from '../Section'
import ShareButton from '../ShareButton'
import { showNumberInCurrencyCents } from '../../lib/showNumber'

const FundraisingSummary = ({
  child,
  classNames,
  onEditCash,
  onShare,
  onToggleOff,
  onToggleOn,
  showOffline,
  showOnline,
  showShare,
  toggled
}) => (
  <Section
    background='secondary'
    heading='Funds Raised'
  >
    <div className={classNames.metrics}>
      {showOnline && (
        <Metric
          label='Online'
          small
        >
          {showNumberInCurrencyCents((child.online_amount_in_cents + child.bonus_amount_in_cents) / 100)}
        </Metric>
      )}
      {showOffline && (
        <Metric
          label='Cash'
          small
        >
          {showNumberInCurrencyCents(child.offline_amount_in_cents / 100)}
          {onEditCash && (
            <InlineButton
              icon='edit'
              onClick={onToggleOn}
            >
              Edit
            </InlineButton>
          )}
          {toggled && (
            <EditCashModal
              child={child}
              onClose={onToggleOff}
              onSubmit={onEditCash}
              onSuccess={onToggleOff}
            />
          )}
        </Metric>
      )}
      <Metric
        label='Raised'
        right
      >
        {showNumberInCurrencyCents(child.total_amount_in_cents / 100)}
      </Metric>
    </div>
    <Progress
      raised={child.total_amount_in_cents}
      target={child.target_cents}
    />
    {showShare && (
      <div className={classNames.shareWrapper}>
        <ShareButton
          icon={false}
          onClose={shared => shared && onShare()}
          spacing={{ y: 0.25, x: 1 }}
          styles={styles.share}
          theme='primary'
          url={child.public_url}
        >
          Click here to raise money online
        </ShareButton>
      </div>
    )}
  </Section>
)

export default compose(
  withToggle,
  withStyles(styles)
)(FundraisingSummary)
