import React from 'react'
import showNumber from '../../lib/showNumber'
import withStyles from 'constructicon/with-styles'
import styles from './styles'
import Tooltip from '../Tooltip'

const Metric = ({
  children,
  label,
  format,
  caption,
  classNames,
  tooltip
}) => {
  const displayNumberFn = showNumber(format)
  const value = !isNaN(parseFloat(children)) ? displayNumberFn(children) : children
  const Tag = tooltip ? Tooltip : 'div'
  const tagProps = tooltip ? { info: tooltip, textAlign: 'left', fontScale: -1.5, verticalOffset: 1 } : {}

  return (
    <div className={classNames.root}>
      <Tag {...tagProps}>
        {label && <label className={classNames.label}>{label}</label>}
        <div className={classNames.value}>
          {value || displayNumberFn(0)}
          {caption && <small className={classNames.caption}>{caption}</small>}
        </div>
      </Tag>
    </div>
  )
}

Metric.defaultProps = {
  format: 'currency_cents'
}

export default withStyles(styles)(Metric)
