import React from 'react'
import TraitsProvider from 'constructicon/traits-provider'
import { Provider } from 'react-redux'
import { StaticRouter, BrowserRouter } from 'react-router-dom'
import { updateBaseURL } from './lib/justgiving'
import configureStore from './store'
import routes from './routes'
import * as traits from '../../../common/traits'

const isServer = () => typeof window === 'undefined'

const RegistrationApp = config => {
  const store = configureStore(config)

  updateBaseURL(config.servicesApiUrl)

  const RouterComponent = isServer() ? StaticRouter : BrowserRouter
  const routerProps = isServer()
    ? {
        location: config.path,
        context: {},
        basename: '/users'
      }
    : {
        basename: '/users'
      }

  return (
    <div id='mount'>
      <Provider store={store}>
        <TraitsProvider traits={traits}>
          <RouterComponent {...routerProps}>{routes}</RouterComponent>
        </TraitsProvider>
      </Provider>
    </div>
  )
}

export default RegistrationApp
