import React from 'react'
import queryString from 'query-string'
import currentApplication from '../../lib/currentApplication'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Button from '../Button'
import ButtonGroup from 'constructicon/button-group'
import ButtonSocial from 'constructicon/button-social'
import ButtonSMS from './ButtonSMS'
import CopyUrl from '../CopyUrl'
import Icon from '../Icon'
import Modal from '../Modal'
import Section from '../Section'

class ShareButton extends React.Component {
  constructor () {
    super()
    this.handleOpenModal = this.handleOpenModal.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleMarkAsShared = this.handleMarkAsShared.bind(this)
    this.state = { shared: false, open: false }
  }

  handleOpenModal () {
    this.setState({ open: true })
  }

  handleCloseModal () {
    const { onClose } = this.props
    const { shared } = this.state
    this.setState({ open: false, shared: false })
    onClose && onClose(shared)
  }

  handleMarkAsShared () {
    this.setState({ shared: true })
  }

  render () {
    const {
      application = currentApplication,
      children,
      classNames,
      disabled,
      icon = true,
      spacing = { y: 0.5, x: 1 },
      theme = 'secondary',
      url
    } = this.props

    const {
      open
    } = this.state

    const emailParams = {
      subject: `Help me raise money for my ${application.beneficiary_title}`,
      body: `Here's a link to my page: ${url}`
    }

    return (
      <div className={classNames.root}>
        <Button
          disabled={disabled}
          onClick={this.handleOpenModal}
          spacing={spacing}
          theme={theme}
        >
          {icon && <Icon name='share' />}
          <span>{children}</span>
        </Button>
        <Modal
          closeColor='light'
          contentLabel='Share your page to raise money'
          isOpen={open}
          size='small'
          onRequestClose={this.handleCloseModal}
        >
          <Section
            background='secondary'
            foreground='light'
            heading='Share your page to raise money'
          >
            <CopyUrl
              onClick={this.handleMarkAsShared}
              url={url}
            />
            <div onClick={this.handleMarkAsShared}>
              <ButtonGroup>
                <p>Share:</p>
                <ButtonSocial
                  borderWidth={2}
                  share
                  type='facebook'
                  url={url}
                />
                <ButtonSocial
                  borderWidth={2}
                  share
                  title='Please donate or share my page!'
                  type='twitter'
                  url={url}
                />
                <ButtonSocial
                  borderWidth={2}
                  share
                  type='google'
                  url={url}
                />
                <Button
                  background='secondary'
                  borderColor='secondary'
                  borderWidth={2}
                  effect='grow'
                  foreground='light'
                  href={`mailto:?${queryString.stringify(emailParams)}`}
                  spacing={0.5}
                  tag='a'
                >
                  <Icon name='mail' />
                </Button>
                <ButtonSMS
                  url={url}
                  message='Please donate or share my page'
                />
              </ButtonGroup>
            </div>
          </Section>
        </Modal>
      </div>
    )
  }
}

export default withStyles(styles)(ShareButton)
