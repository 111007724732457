import merge from 'lodash/merge'

export default (props, traits) => {
  const { rhythm, scale, colors, radiuses, mediaQuery } = traits

  const {
    background,
    dark,
    foreground,
    info,
    margin = 1,
    noBodyPadding,
    padding = 1,
    styles
  } = props

  const backgroundColor = colors[background] || colors.primary
  const foregroundColor = colors[foreground] || colors.light

  const baseStyles = {
    root: {
      marginTop: rhythm(margin),
      marginBottom: rhythm(margin),
      padding: 0,
      textAlign: 'center',
      ':first-child': {
        marginTop: 0
      },
      [mediaQuery('sm')]: {
        textAlign: 'left'
      }
    },

    header: {
      position: 'relative',
      padding: `${rhythm(0.75)} ${rhythm(1)}`,
      backgroundColor,
      color: foregroundColor,
      ':after': {
        content: '""',
        display: 'table',
        clear: 'both'
      },
      [mediaQuery('md')]: {
        padding: `${rhythm(1)} ${rhythm(padding)}`
      }
    },

    headerContent: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'center',
      margin: rhythm(-0.5),
      '> *': {
        margin: rhythm(0.5)
      },
      [mediaQuery('md')]: {
        justifyContent: 'space-between'
      }
    },

    buttons: {
      margin: rhythm(0.25)
    },

    heading: {
      marginBottom: 0,
      fontSize: scale(1.5),
      padding: info && `0 ${rhythm(2)}`,
      [mediaQuery('md')]: {
        padding: 0
      }
    },

    info: {
      position: 'absolute',
      zIndex: 12,
      top: '50%',
      width: rhythm(1.5),
      height: rhythm(1.5),
      lineHeight: rhythm(1.5),
      marginTop: rhythm(-0.75),
      right: rhythm(1),
      borderRadius: '50%',
      border: `2px solid ${colors.light}`,
      textAlign: 'center',
      cursor: 'help',
      transition: 'all 200ms ease',
      ':hover': {
        background: 'rgba(255,255,255,0.25)'
      },
      ':hover div': {
        transform: 'translateX(-66%)',
        opacity: 1,
        visiblity: 'visible',
        pointerEvents: 'all',
        cursor: 'default'
      },
      svg: {
        marginTop: rhythm(-0.25)
      },
      [mediaQuery('md')]: {
        width: rhythm(2),
        height: rhythm(2),
        lineHeight: rhythm(2),
        marginTop: rhythm(-1),
        right: rhythm(1.5)
      }
    },

    infoText: {
      visiblity: 'hidden',
      opacity: 0,
      pointerEvents: 'none',
      position: 'absolute',
      top: '100%',
      left: '50%',
      marginTop: rhythm(0.5),
      transform: `translateX(-66%) translateY(${rhythm(-0.5)})`,
      lineHeight: '1.333em',
      background: colors.light,
      color: colors.dark,
      padding: rhythm(0.5),
      minWidth: rhythm(8),
      fontSize: scale(-1),
      borderRadius: radiuses.medium,
      boxShadow: '0 1px 5px rgba(0,0,0,0.25)',
      transition: 'all 200ms ease',
      ':before': {
        content: '""',
        position: 'absolute',
        top: rhythm(-0.333),
        left: '66%',
        background: colors.light,
        width: rhythm(0.666),
        height: rhythm(0.666),
        marginLeft: rhythm(-0.333),
        transform: 'rotate(45deg)'
      },
      ':after': {
        content: '""',
        position: 'absolute',
        top: rhythm(-2),
        left: rhythm(-2),
        bottom: rhythm(-2),
        right: rhythm(-2),
        zIndex: -1
      }
    },

    button: {
      padding: `${rhythm(0.25)} ${rhythm(0.5)}`,
      fontSize: scale(-1),
      marginTop: rhythm(0.125),
      marginBottom: 0
    },

    body: {
      background: dark ? colors.secondaryLight : colors.light,
      color: dark ? colors.light : colors.dark,
      padding: noBodyPadding || rhythm(1),
      [mediaQuery('md')]: {
        padding: noBodyPadding || rhythm(padding)
      }
    }
  }

  return merge(baseStyles, styles)
}
