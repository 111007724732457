import React from 'react'
import Grid from 'constructicon/grid'
import GridColumn from 'constructicon/grid-column'
import reject from 'lodash/reject'

const Advertisements = ({
  hidePrivateAds = false,
  advertisements = [],
  columnWidths = {}
}) => {
  const ads = hidePrivateAds ? reject(advertisements, { publicly_visible: false }) : advertisements
  return (
    <div>
      <Grid spacing={0.5}>
        {ads.map(ad => (
          <GridColumn
            key={ad.id}
            {...columnWidths}
          >
            <a
              href={ad.url}
              target='_blank' rel='noopener noreferrer'
            >
              <img
                alt={ad.name}
                src={ad.image.url}
              />
            </a>
          </GridColumn>
        ))}
      </Grid>
    </div>
  )
}

export default Advertisements
