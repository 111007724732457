import React from 'react'
import { connect } from 'react-redux'

import AuthForm from '../../components/AuthForm'
import AppLayout from '../../components/AppLayout'
import CheckAccount from '../../components/CheckAccount'

const Introduction = ({ config }) => {
  return (
    <AppLayout>
      <AuthForm
        heading={`Welcome to ${config.application.application_title}`}
        formComponent={CheckAccount}
      />
    </AppLayout>
  )
}

const mapStateToProps = state => ({
  config: state.config
})

export default connect(mapStateToProps)(Introduction)
