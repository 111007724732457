import React from 'react'
import { NavLink } from 'react-router-dom'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

const Tabs = ({ classNames }) => (
  <nav className={classNames.root}>
    <NavLink
      className={classNames.link}
      activeClassName={classNames.active}
      to='/sign_up'
    >
      Register
    </NavLink>
    <NavLink
      className={classNames.link}
      activeClassName={classNames.active}
      to='/sign_in'
    >
      Login
    </NavLink>
  </nav>
)

export default withStyles(styles)(Tabs)
