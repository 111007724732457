import React from 'react'

import AuthForm from '../../components/AuthForm'
import { Link } from 'react-router-dom'
import LoginForm from '../../components/LoginForm'
import AppLayout from '../../components/AppLayout'

const Login = () => (
  <AppLayout>
    <AuthForm
      heading='Parent/Guardian Login'
      formComponent={LoginForm}
      footer={
        <span>
          <Link to='/sign_up'>Need an account?</Link>{' '}|{' '}
          <Link to='/sign_in/reset_password'>Forgot your password?</Link>
        </span>
      }
    />
  </AppLayout>
)

export default Login
