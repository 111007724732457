import React, { Component } from 'react'
import copy from 'copy-to-clipboard'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Button from '../../../common/components/Button'
import Icon from '../../../common/components/Icon'

class CopyUrl extends Component {
  constructor () {
    super()
    this.handleCopyUrl = this.handleCopyUrl.bind(this)
    this.handleSelectAll = this.handleSelectAll.bind(this)

    this.state = {
      copied: false
    }
  }

  handleCopyUrl () {
    const { onClick, url } = this.props
    copy(url) && this.setState({ copied: true })
    onClick && onClick()
  }

  handleSelectAll () {
    this.input.select()
  }

  render () {
    const {
      url,
      classNames
    } = this.props

    const { copied } = this.state

    return (
      <div className={classNames.root}>
        <input
          className={classNames.input}
          onClick={this.handleSelectAll}
          readOnly
          ref={input => { this.input = input }}
          value={url}
        />
        <Button onClick={this.handleCopyUrl} size={-1}>
          <Icon name={copied ? 'check' : 'link'} />
          <span>{copied ? 'Copied!' : 'Copy'}</span>
        </Button>
      </div>
    )
  }
}

export default withStyles(styles)(CopyUrl)
