const hexToRgba = (hexString, alpha = 0) => {
  const values = hexString.replace('#', '')
  const regex = new RegExp('.{1,' + values.length / 3 + '}', 'g')
  const rgb = values.match(regex).map(val => parseInt(val, 16))
  return `rgba(${rgb.join(', ')}, ${alpha})`
}

export default ({ application = {} }, { colors, mediaQuery, scale, rhythm }) => {
  const backgroundColor = application.registration_background_color || colors.secondary

  return {
    wrapper: {
      outer: {
        position: 'relative',
        backgroundImage: `url(${application.registration_background_url})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundColor,
        backgroundPosition: 'center',
        ':before': {
          content: '""',
          position: 'absolute',
          zIndex: 0,
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundImage: `linear-gradient(${hexToRgba(backgroundColor, 0.9)} 10%, ${hexToRgba(backgroundColor)})`,

          [mediaQuery('sm')]: {
            position: 'fixed',
            right: 'auto',
            backgroundImage: `linear-gradient(90deg, ${hexToRgba(backgroundColor, 0.8)} 40%, ${hexToRgba(backgroundColor)})`,
            width: '75%'
          }
        }
      },
      root: {
        display: 'flex',
        position: 'relative',
        padding: rhythm([1, 0.5]),
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: rhythm(24),
        minHeight: '100vh',
        [mediaQuery('sm')]: {
          padding: rhythm([1, 1.5]),
          maxWidth: rhythm(48)
        }
      }
    },
    blurb: {
      root: {
        padding: rhythm([0, 0.5]),
        [mediaQuery('sm')]: {
          padding: 0
        }
      }
    },
    copy: {
      margin: 'auto',
      maxWidth: rhythm(20),
      fontWeight: 500,
      fontSize: scale(-0.5),
      textAlign: 'center',
      color: colors.light,
      textShadow: '0 1px 3px rgba(0,0,0,0.25)',
      ...application.app_uid === 'jumping-june-nz' && {
        color: colors.dark,
        textShadow: '0 1px 3px rgba(255,255,255,0.25)'
      },
      a: {
        color: 'currentColor',
        textDecoration: 'underline'
      },
      [mediaQuery('sm')]: {
        maxWidth: rhythm(16),
        textAlign: 'left',
        margin: 0
      },
      [mediaQuery('md')]: {
        fontSize: scale(0)
      }
    },
    logo: {
      display: 'block',
      width: 'auto',
      maxHeight: '100px',
      margin: `0 auto ${rhythm(1)}`,

      [mediaQuery('sm')]: {
        margin: `0 0 ${rhythm(1)}`
      },

      [mediaQuery('md')]: {
        maxHeight: '200px'
      }
    }
  }
}
