import React from 'react'

import AuthForm from '../../components/AuthForm'
import { Link } from 'react-router-dom'
import ResetPasswordForm from '../../components/ResetPasswordForm'
import AppLayout from '../../components/AppLayout'

const ResetPassword = () => (
  <AppLayout>
    <AuthForm
      heading='Reset Your JustGiving Password'
      formComponent={ResetPasswordForm}
      footer={<Link to='/sign_up'>Need an account?</Link>}
    />
  </AppLayout>
)

export default ResetPassword
